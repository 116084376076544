import React from 'react'

// import Layout from '@components/Layout'
import Layout from '../components/Layout'
import { t } from '../components/Translator'

// @ts-ignore
import content from '../../content/404.yaml'

const style = {
    padding: '40px 10px 40px 10px',
    width: '100%',
    margin: 'auto',
    textAlign: 'center'
}

const NotFoundPage: React.FC = () => {

    return (
        <Layout head={content.head}>
            <div style={style}>
                <h1>{t(content.head.description)}</h1>
            </div>
        </Layout>
    )
}

export default NotFoundPage